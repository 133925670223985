import React, { useEffect } from 'react';

import gsap from 'gsap';

import { Logo, SEO, LandingPage, FullHeightWrapper } from '../components';
import { ANIMATION_HELPERS } from '../const/animations.const';
import { IAppContext } from '../context/app/app.types';
import { useAppContext } from '../hooks/context/appContext.hook';
import * as grid from '../scripts/grid';

const IndexPage = () => {
	const {
		setIsGridVisible,
		isGridInitialised,
		setIsGridEnabled,
		setIsMenuButtonDark,
		isCampaignVisible,
		setIsCampaignVisible,
	}: IAppContext = useAppContext();

	useEffect(() => {
		if (isCampaignVisible) {
			return;
		}

		gsap.fromTo(
			'.c-logo',
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: ANIMATION_HELPERS.duration,
				ease: ANIMATION_HELPERS.ease,
			},
		);

		if (isGridInitialised) {
			grid.reinit();
		}

		setIsGridVisible(true);
		setIsGridEnabled(true);
		setIsMenuButtonDark(false);

		return () => {
			setIsGridEnabled(false);
			setIsMenuButtonDark(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCampaignVisible]);

	const handleCloseCampaign = () => {
		setIsCampaignVisible(false);
	};

	return (
		<div>
			<SEO />
			<FullHeightWrapper>
				{isCampaignVisible ? (
					<LandingPage handleOnClick={handleCloseCampaign} />
				) : (
					<div className="c-logo--large c-logo--light">
						<Logo />
					</div>
				)}
			</FullHeightWrapper>
		</div>
	);
};

export default IndexPage;
